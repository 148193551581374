<template>
    <div>
        <!-- FEATURES START -->
        <section class="section bg-light" id="service">
            <!-- <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="features-absolute py-5 bg-transparent">
                            <div class="features-list">
                                <div class="feature-item container">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-shield-fill-check" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.8 11.8 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7 7 0 0 0 1.048-.625 11.8 11.8 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.54 1.54 0 0 0-1.044-1.263 63 63 0 0 0-2.887-.87C9.843.266 8.69 0 8 0m2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793z"/>
</svg>
                                    <h4>Your Perfect List Price</h4>
                                    <p>Texsmart compares 1000's of similar homes within minutes to find your perfect list price, ensuring you get the most money for your home.</p>
                                </div>
                                <div class="feature-item container">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-megaphone-fill" viewBox="0 0 16 16">
  <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25 25 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009l.496.008a64 64 0 0 1 1.51.048m1.39 1.081q.428.032.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a66 66 0 0 1 1.692.064q.491.026.966.06"/>
</svg>
                                    <h4>Modern Marketing</h4>
                                    <p>Our software runs 150 unique marketing plays to find the perfect buyer for your home and get it sold 37% faster than the traditional real estate agent.</p>
                                </div>
                                <div class="feature-item container">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-hand-thumbs-up-fill" viewBox="0 0 16 16">
  <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a10 10 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733q.086.18.138.363c.077.27.113.567.113.856s-.036.586-.113.856c-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.2 3.2 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.8 4.8 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z"/>
</svg>
                                    <h4>We Bring More Buyers</h4>
                                    <p>More buyers means more offers. 90% of home buyers start their home search online. We list your home on 1000’s of websites that get over 100 million views per month total.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="container mt-10 mt-10">
                <div style="background: url('images/map.png') center center;">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="position-relative me-lg-5">
                                <img src="../../assets/images/kc_happy.jpeg" class="rounded img-fluid mx-auto d-block" alt="">
                                <!-- <div class="play-icon">
                                    <a href="#!" @click="toggle" data-type="youtube" data-id="yba7hPeTSjk"
                                        class="play-btn lightbox">
                                        <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                                    </a>
                                </div> -->
                            </div>
                        </div><!--end col-->

                        <div v-for="item3 in BusinessData" :key="item3" class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div class="section-title">
                                <h4 class="title mb-3">{{ item3.title }}</h4>
                                <p class="text-muted">{{ item3.desc }}</p>
                                <ul class="list-unstyled text-muted">
                                    <li class="mb-1"><span class="text-primary h5 me-2"><i :class=item3.icon></i></span>{{
                                        item3.icondesc }}</li>
                                    <li class="mb-1"><span class="text-primary h5 me-2"><i :class=item3.icon></i></span>Absolutely free to use. We respect your privacy.</li>
                                    <li class="mb-1"><span class="text-primary h5 me-2"><i :class=item3.icon></i></span>{{
                                        item3.icondesc2 }}</li>
                                </ul>

                                <div class="d-inline-block">
                                    <div class="pt-3 d-flex align-items-center border-top">
                                        <i class="uil uil-envelope text-primary me-2 fs-1"></i>
                                        <div class="content">
                                            <p class="mb-0">Need More Help?</p>
                                            <a href="" class="text-dark h6">
                                                <router-link :to="{ name: 'GetStarted' }">Ask us your question
                                                </router-link></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><!--end col-->
                    </div><!--end row-->
                </div>
            </div><!--end container-->

            <!-- <div class="container mt-5">
                <div class="row">
                    <div v-for="item in Datas" :key="item" class="col-md-3 col-6">
                        <div class="counter-box position-relative text-center">


                            <h3 class="mb-0 fw-semibold mt-2 d-flex justify-content-center "><count-up class="counter-value"
                                    :end-val="item.target"></count-up>{{ item.symbol }}</h3>
                            <span class="counter-head text-muted">{{ item.name }}</span>

                        </div>
                    </div>
                </div>
            </div> -->
            <div
                class="position-absolute  bottom-0 start-0 end-0 sm:h-2/3 h-4/5 bg-gradient-to-b from-teal-400 to-teal-500">
            </div>
        </section><!--end section-->
        <!-- End -->
        <div :class="isActive ? 'position-fixed start-0 top-0 w-100 h-100' : 'd-none'" class="bg-black "
            style="z-index: 9999;">
            <div class="position-absolute top-50 start-50 translate-middle">
                <div class="position-relative d-inline-block">
                    <iframe src="" width="560" height="315" frameborder="0"
                        class="video-frame"></iframe>
                    <router-link to="/" class="mdi mdi-close   m-3 text-light h4 position-class"
                        @click="toggle"></router-link>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
//import CountUp from 'vue-countup-v3'

export default {
    name: "FeatureVue",
    data() {
        return {
            isActive: false,
            FeatureData: [
                {
                    icon: "mdi mdi-language-php rounded h4",
                    title: "Financial Planning",
                    desc: "Horem ipsum dolor consectetuer Lorem simply dummy orem commo.",
                },
                {
                    icon: "mdi mdi-file-image rounded h4",
                    title: "Quality Resourcing",
                    desc: "When an unknown printer took a galley of type and scrambled it.",
                },
                {
                    icon: "uil uil-camera rounded h4",
                    title: "Business Services",
                    desc: "It has survived not only five centuries but leap in typesetting.",
                },

            ],
            FeatureData2: [
                {
                    icon: "mdi mdi-google-glass rounded h4",
                    title: "Software And Research",
                    desc: "It was popularised with the release of Letraset sheets sit amet.",
                },
                {
                    icon: "mdi mdi-source-commit rounded h4",
                    title: "Travel And Aviation",
                    desc: "It is a long established fact that a reader will be distracted.",
                },
                {
                    icon: "mdi mdi-code-tags rounded h4",
                    title: "Healthcare Services",
                    desc: "A point of using lorem ipsum is that it has normal distribution.",
                },
            ],
            BusinessData: [
                {
                    title: "Welcome to TexSmart Realty",
                    title2: " Hassle Free Business",
                    desc: "As a full service real estate professional in Texas, We work with buyers, sellers and investors in real estate transactions spanning all of the price ranges and property types.Our website contains ALL listings from ALL Real Estate Agents, there's no need to look anywhere else for your next home.",
                    icon: "uil uil-check-circle align-middle",
                    icondesc: "We keep updating you on latest Listings and Price Changes.",
                    icondesc2: "Automatically get signs delivered to your house + professional photographs taken."
                }
            ],
            Datas: [
                {
                    name: "Projects",
                    target: "40",
                    symbol: "+"
                },
                {
                    name: "Clients",
                    target: "200",
                    symbol: "+"
                },
                {
                    name: "Members",
                    target: "457",
                    symbol: "K"
                },
                {
                    name: "Employee",
                    target: "150",
                    symbol: "+"
                },
            ]
        }
    },
    /* components: {
        CountUp
    }, */
    methods: {
        toggle() {
            if (!this.isActive) {
                this.isActive = true;
            } else {
                this.isActive = false;
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.position-class {
    position: absolute;
    bottom: 82%;
    left: 92%;
}
.features-list {
  display: grid !important;
  grid-template-columns: auto auto auto;
  gap: 20px;
}

@media (max-width: 768px) {
  .features-list {
    grid-template-columns: auto;
  }
}

.feature-item {
    padding: 20px 30px 20px 30px;
    background: white;
    border-radius: 25px;
    //box-shadow: 0 0 10px 0 rgb(128,128,128,0.6);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.feature-item svg {
    color: #008FD5;
}
.feature-item h4 {
    margin-top: 15px;
}
</style>