import { createWebHistory, createRouter } from "vue-router";
import Index from "../pages/index-startup.vue";


const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "/auth-login",
    name: "AuthLogin",
    component: () => import("@/pages/auth-login.vue"),
  },
  {
    path: "/auth-signup",
    name: "AuthSignup",
    component: () => import("@/pages/auth-signup.vue"),
  },
  {
    path: "/auth-reset-password",
    name: "AuthResetPass",
    component: () => import("@/pages/auth-reset-password.vue"),
  },
  {
    path: "/our-team",
    name: "OurAgents",
    component: () => import("@/components/Team/OurAgents.vue"),
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: () => import("@/pages/maintenance.vue"),
  },
  {
    path: "/get-started",
    name: "GetStarted",
    component: () => import("@/pages/get-started.vue"),
  },
  {
    path: "/contact-page",
    name: "ContactPage",
    component: () => import("@/pages/ContactPage.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "Index",
    component: () => import('@/pages/index-startup.vue'),
  },
];


const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
        return savedPosition;
    }
    if (to.hash) {
        return { el: to.hash, behavior: 'smooth' };
    } else {
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 5);
    }
},
});

export default router;