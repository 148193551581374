<template>
    <div>
        <!-- Footer Start -->
        <footer class="bg-footer">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="footer-py-60">
                            <div class="row">
                                <div class="col-lg-4 col-12 mb-lg-0 mb-md-4 pb-lg-0 pb-md-2">
                                    <h5 class="footer-head">Company</h5>
                                    <ul class="list-unstyled footer-list mt-4">
                                        <li class="ms-0"><a href="" class="text-foot">
                                                                                            About Us</a></li>
                                        <li class="ms-0"><a href="" class="text-foot">
                                                                                            FAQ</a></li>
                                        <li class="ms-0"><a href="" class="text-foot">
                                                                                            Reviews</a></li>
                                        <li class="ms-0"><a href="" class="text-foot">
                                                                                            Privacy Policy</a></li>
                                    </ul>
                                </div><!--end col-->

                                <div class="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 class="footer-head">Quick Links</h5>
                                    <ul class="list-unstyled footer-list mt-4">
                                        <li class="ms-0"><a href="https://americansmartlending.com/" class="text-foot"><i
                                                    class="uil uil-angle-right-b me-1"></i> Mortgage Site</a></li>
                                        <li class="ms-0"><a href="" class="text-foot"><i
                                                    class="uil uil-angle-right-b me-1"></i> Closing Cost Calculator</a></li>
                                        <li class="ms-0"><a href="" class="text-foot"><i
                                                    class="uil uil-angle-right-b me-1"></i> Blog</a></li>
                                        <li class="ms-0"><a href="" class="text-foot"><i
                                                    class="uil uil-angle-right-b me-1"></i> Market Compare</a></li>
                                    </ul>
                                </div><!--end col-->

                                <div class="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 class="footer-head">Get In Touch</h5>
                                    <ul class="list-unstyled footer-list mt-3">
                                        <li class="ms-0"><a href="https://maps.app.goo.gl/PWAGmp4XDbUFZ7TW7" class="text-foot">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg>&nbsp;
                                            1560 E.Southlake Blvd Ste.100 <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Southlake, TX, 76092
                                        </a>

                                        </li>
                                        <li class="ms-0"><a href="tel:+14695256725" class="text-foot">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg>&nbsp;
                                            +1(469)-525-6725</a></li>
                                        <li class="ms-0"><a href="mailto:kc@texsmartrealty.com"
                                                class="text-foot"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg>&nbsp;&nbsp;
            kc@TexSmartRealty.com</a>   </li>
                                    </ul>
                                </div><!--end col-->
                            </div><!--end row-->
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->

            <!-- <div class="footer-py-30 footer-bar">
                <div class="container text-center">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-sm-4 mt-4 mt-sm-0">
                            <ul class="list-unstyled social-icon text-sm-end foot-social-icon mb-0">
                                <li class="list-inline-item"><a href="" target="_blank"
                                        class="rounded"><i class="uil uil-shopping-cart align-middle"
                                            title="Buy Now"></i></a>
                                </li>
                                <li class="list-inline-item"><a href="" target="_blank"
                                        class="rounded"><i class="uil uil-dribbble align-middle" title="dribbble"></i></a>
                                </li>
                                <li class="list-inline-item"><a href="" target="_blank"
                                        class="rounded"><i class="uil uil-behance" title="Behance"></i></a>
                                </li>
                                <li class="list-inline-item"><a href=""
                                        target="_blank" class="rounded"><i class="uil uil-linkedin"
                                            title="Linkedin"></i></a>
                                </li>
                                <li class="list-inline-item"><a href="" target="_blank"
                                        class="rounded"><i class="uil uil-facebook-f align-middle" title="facebook"></i></a>
                                </li>
                                <li class="list-inline-item"><a href=""
                                        target="_blank" class="rounded"><i class="uil uil-instagram align-middle"
                                            title="instagram"></i></a>
                                </li>
                                <li class="list-inline-item"><a href="" target="_blank"
                                        class="rounded"><i class="uil uil-twitter align-middle" title="twitter"></i></a>
                                </li>
                                <li class="list-inline-item"><a href="" class="rounded"><i
                                            class="uil uil-envelope align-middle" title="email"></i></a>
                                </li>
                                <li class="list-inline-item"><a href="" target="_blank"
                                    class="rounded"><i class="uil uil-file align-middle" title="customization"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> -->
    </footer><!--end footer-->
    <!-- Footer End -->
</div></template>

<script>
export default {
    name: "FooterVue",
    data() {
        return {
            date: new Date().getFullYear()
        }
    }
}
</script>

<style lang="scss" scoped></style>