<template>
    <div>
        <section class="section" id="project">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col">
                        <div class="section-title text-center mb-0 pb-2">
                            <h4 class="title mb-3">We help you in both selling and buying</h4>
                            <!-- <p class="text-muted mx-auto para-desc mb-0">Launch your campaign and benefit from our expertise
                                on designing and managing conversion centered bootstrap v5 html page.</p> -->
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row">
                    <div v-for="item in ProjectData" :key="item" class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                        <div class="card">
                            <a href="">
                                <router-link to="/get-started">
                                    <img :src="item.image" class="img-fluid" alt="work-image">
                                </router-link>
                            </a>
                            <div class="card-body p-0">                              
                                <div class="bg-dark"></div>
                                <h6 class="display-9">{{ item.title }}</h6>
                                <!-- <div class="content">
                                    <h6 class="mb-0"><a href="" class="text-dark title">{{ item.name }}</a></h6>
                                    <p class="text-dark tag mb-0">{{ item.title }}</p>
                                </div> -->
                                <!-- <div class="icons text-center">
                                    <a @click="showMultiple" class="pointer-event  btn btn-icon btn-pills lightbox"><i
                                            :data-feather="item.icon" class="fea icon-sm image-icon"></i></a>
                                </div> -->
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- Project End -->
        <div>
            <vue-easy-lightbox :visible="visibleRef" :imgs="imgsRef" :index="indexRef" @hide="onHide"></vue-easy-lightbox>
        </div>
    </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import { ref } from 'vue'

import feather from 'feather-icons';
export default {
    name: "ProjectVue",
    data() {
        return {
            ProjectData: [
                {
                    image: require('../assets/images/portfolio/1.jpg'),
                    icon: "camera",
                    name: "Mockup Collection",
                    title: "I'm a real estate investor"
                },
                {
                    image: require('../assets/images/portfolio/2.jpg'),
                    icon: "camera",
                    name: "Mockup Collection",
                    title: "I'm a first time home seller"
                },
                {
                    image: require('../assets/images/portfolio/3.jpg'),
                    icon: "camera",
                    name: "I'm an experienced home seller",
                    title: "I'm an experienced home seller"
                },
                {
                    image: require('../assets/images/portfolio/4.jpg'),
                    icon: "camera",
                    name: "I'm a for sale by owner seller",
                    title: "I'm a for sale by owner seller"
                },
                /* {
                    image: require('../assets/images/portfolio/5.jpg'),
                    icon: "camera",
                    name: "Company V-card",
                    title: "V-card"
                },
                {
                    image: require('../assets/images/portfolio/6.jpg'),
                    icon: "camera",
                    name: "Mockup box with paints",
                    title: "Photography"
                },
                {
                    image: require('../assets/images/portfolio/7.jpg'),
                    icon: "camera",
                    name: "Coffee cup",
                    title: "Cups"
                },
                {
                    image: require('../assets/images/portfolio/8.jpg'),
                    icon: "camera",
                    name: "Pen and article",
                    title: "Article"
                }, */

            ]

        }
    },
    mounted() {
        feather.replace();
    },
    components: {
        VueEasyLightbox
    },
    methods: {
        goToPage() {
            return this.$router.push('get-started');
        }
    },
    setup() {
        const visibleRef = ref(false)
        const indexRef = ref(0)
        const imgsRef = ref([])


        const onShow = () => {
            visibleRef.value = true
        }
        const showMultiple = () => {
            imgsRef.value = [

                require('../assets/images/portfolio/1.jpg'),
                require('../assets/images/portfolio/2.jpg'),
                require('../assets/images/portfolio/3.jpg'),
                require('../assets/images/portfolio/4.jpg'),
                require('../assets/images/portfolio/5.jpg'),
                require('../assets/images/portfolio/6.jpg'),
                require('../assets/images/portfolio/7.jpg'),
                require('../assets/images/portfolio/8.jpg'),
            ]

            indexRef.value = 0
            onShow()
        }
        const onHide = () => (visibleRef.value = false)

        return {
            visibleRef,
            indexRef,
            imgsRef,
            showMultiple,
            onHide
        }
    }
}
</script>

<style lang="scss" scoped>

    .card {
        transition: all 0.5s ease;
        border-radius:25px !important;
        background-color: #2f333a;
    }

    .card:hover {
        cursor: pointer;
    }

    .card img {
        transition: all 0.5s ease;
    }
    .card:hover img {
        transform: scale(1.1);  
    }

    .card img {
        border-radius:25px !important;
        opacity: 0.4;
    }

    .card {
        position: relative;
        overflow: hidden;
    }

    .card h6 {
        position: absolute;
        margin-top: -50%;
        margin-left: 10%;
        margin-right: 10%;
        color: white;
    }

</style>