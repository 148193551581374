<template>
    <div>
        <!-- CTA Start -->
        <section id="cta" class="section" data-jarallax='{"speed": 0.5}'
            :style='{ backgroundImage: "url(" + image + ")", backgroundPosition: "center", backgroundSize: "cover" }'>
            <div class="bg-overlay"></div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col">
                        <div class="section-title text-center">
                            <h4 class="title text-white mb-3">{{ title }}</h4>
                            <p class="text-white mx-auto para-desc mb-0">{{ desc }}</p>

                            <div class="mt-4 pt-2">
                                <a href="https://americansmartlending.com" class="btn btn-primary">
                                        <h6 style="color: white;">Click here to go to mortgage site</h6>
                                </a>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- CTA End -->
    </div>
</template>

<script>
export default {
    name: "ctaVue",
    data() {
        return {

            image: require("../../assets/images/mortgagePic.jpg"),
            title: "Are You Looking for a Home Loan?",
            desc: "Visit our mortgage site to apply online or speak with one of our mortgage experts today. Your dream home is just a click away!",
            button: "Click here go to mortgage site"

        }
    },
}
</script>

<style lang="scss" scoped></style>